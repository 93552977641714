// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/query-vehicle.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".backgroundImage[data-v-8e146a7c] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 100%;\n  background-position: 100% 100%;\n  padding: 40px 20px;\n  padding-top: 0;\n}\n.search[data-v-8e146a7c] {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  color: #fff;\n}\n.search .content-title[data-v-8e146a7c] {\n  font-size: 30px;\n  font-weight: bold;\n  margin: 5% 0;\n}\n.search .search-div .change-div[data-v-8e146a7c] {\n  display: flex;\n  align-items: center;\n}\n.search .search-div .change-div .choose-tabs[data-v-8e146a7c] {\n  padding: 3px 13px;\n  cursor: pointer;\n  font-size: 16px;\n}\n.choose-change[data-v-8e146a7c] {\n  position: relative;\n  background: #2FC3CD;\n  border-radius: 2px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.choose-change .pointTo-img[data-v-8e146a7c] {\n  top: 85%;\n  position: absolute;\n  display: block;\n}\n.pointTo-img[data-v-8e146a7c] {\n  top: 85%;\n  position: absolute;\n  display: none;\n}\n.remark-div[data-v-8e146a7c] {\n  font-size: 12px;\n}\n[data-v-8e146a7c] .ant-divider-vertical {\n  margin: 0 15px;\n}\n[data-v-8e146a7c] .ant-input-search-button {\n  height: 40px;\n  display: flex;\n  align-items: center;\n  background-color: #2FC3CD;\n  border-color: #2FC3CD;\n}\n[data-v-8e146a7c] .ant-input {\n  padding: 8px;\n}\n", ""]);
// Exports
module.exports = exports;
