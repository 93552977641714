<template>
  <div>
    <!-- 搜索模块 -->
    <div class="backgroundImage">
      <div class="search">
        <div class="content-title">华月运车一键查车服务平台</div>
        <div class="search-div">
          <div class="change-div">
            <div class="choose-tabs choose-change" @click="choose(0)">
              <div>车牌号</div>
              <img class="pointTo-img" src="@/assets/images/choose-change.png" />
            </div>
            <a-divider style="background:#fff;" type="vertical" />
            <div class="choose-tabs" @click="choose(1)">
              <div>订单号</div>
              <img class="pointTo-img" src="@/assets/images/choose-change.png" />
            </div>
            <a-divider style="background:#fff;" type="vertical" />
            <div class="choose-tabs" @click="choose(2)">
              <div>客户电话</div>
              <img class="pointTo-img" src="@/assets/images/choose-change.png" />
            </div>
          </div>
          <a-input-search v-model:value="content" :placeholder="placeholderTitle" @search="onSearch" style="margin: 20px 0">
            <template #enterButton>
              <div style="height:100%;height:40px;line-height:40px">
                <SearchOutlined style="font-size: 18px;" />一键查车
              </div>
            </template>
          </a-input-search>
          <div class="remark-div">查询说明：该模块用仅用于单个车辆及单个客户订单查询，如需查看自己所有订单，请前往 “订单列表” 模块查看。</div>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div>
      <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" :pagination="pagination"
        :loading="loading" @change="handleTableChange">
        <template #orderId="{ record }">
          <a-typography-paragraph :copyable="{ text: record.id }">
            {{ record.id }}
          </a-typography-paragraph>
        </template>
        <template #operation="{ record }">
          <div>
            <a @click="detailNavigate(record.id)">详情</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { page } from '@/api/trans/order'
import { SearchOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'

export default {
  components: {
    SearchOutlined,
  },
  setup () {
    const router = useRouter()
    const state = reactive({
      content: '',
      placeholderTitle: '请输入至少4位车牌号',
      loading: false,
      typeNum: 0,
      listData: [],
      columns: [
        { title: '订单号', dataIndex: 'orderId', slots: { customRender: 'orderId' } },
        { title: '客户名称', dataIndex: 'customerName' },
        { title: '始发地', dataIndex: 'startAddress' },
        { title: '目的地', dataIndex: 'endAddress' },
        { title: '所属区域', dataIndex: 'officeOrgName' },
        { title: '结算方式', dataIndex: 'orderSettlement.label' },
        { title: '结算状态', dataIndex: 'settlementStatus.label' },
        { title: '创建时间', dataIndex: 'orderTime' },
        { title: '业务员', dataIndex: 'salesman' },
        { title: '产品', dataIndex: 'productName' },
        { title: '状态', dataIndex: 'orderStatus', slots: { customRender: 'orderStatus' } },
        { title: '操作', dataIndex: 'operation', width: 200, slots: { customRender: 'operation' } }
      ],
      pagination: {
        current: 1,
        size: 10,
        total: null,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      // loadData()
    })
    const loadData = () => {
      if (state.content !== '' && state.content !== undefined && state.content !== null) {
        state.loading = true
        page({
          vinNo: state.typeNum === 0 ? state.content : '',
          orderId: state.typeNum === 1 ? state.content : '',
          customerMobile: state.typeNum === 2 ? state.content : '',
          current: state.pagination.current,
          size: state.pagination.pageSize
        }).then(res => {
          // console.log(res)  // 主要动态数据
          if (res.code === 10000) {
            state.listData = res.data.records
            state.pagination.current = res.data.current
            state.pagination.pageSize = res.data.size
            state.pagination.total = res.data.total
          }
        }).finally(() => {
          state.loading = false
        })
      }else message.error('请填写查询条件')
    }
    const onSearch = () => {
      switch (state.typeNum) {
        case 0:
          if(state.content.length >= 4) loadData()
          else message.error('请输入至少4位车牌号')
          break
        case 1:
          if(state.content.length >= 4) loadData()
          else message.error('请输入至少8位订单号')
          break
        case 2:
          if(state.content.length >= 4) loadData()
          else message.error('请输入至少4位电话号码')
          break
      }
    }
    const choose = (num) => {
      state.typeNum = num
      state.content = ''
      let DOM = document.querySelectorAll('.choose-tabs')
      DOM.forEach((item, index) => {
        item.classList.remove('choose-change')
      })
      DOM[num].classList.add('choose-change')
      switch (num) {
        case 0:
          state.placeholderTitle = '请输入至少4位车牌号'
          break
        case 1:
          state.placeholderTitle = '请输入至少8位订单号'
          break
        case 2:
          state.placeholderTitle = '请输入至少4位电话号码'
          break
      }
    }
    const detailNavigate = id => {
      router.push({
        name: 'orderDetail',
        params: {
          id: id
        }
      })
    }
    return {
      ...toRefs(state),
      choose,
      onSearch,
      detailNavigate
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
